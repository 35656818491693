import React from "react";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import logo from "../../assets/icons/logo-white.png"
const quickLinks = [
  {
    path: "/",
    display: "Home",
  },
  {
    path: "/about-us",
    display: "About Us",
  },
  {
    path: "/our-fleet",
    display: "Our Fleet",
  },
  {
    path: "/our-blogs",
    display: "Our Blog",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12" >
            <div className="logo footer__logo">
              <h1>
                <Link to="/" className="d-flex align-items-center gap-2 pr-2">
                  <div>
                    <img src={logo} alt="" className="footer-logo"/>
                  </div>
                </Link>
              </h1>
            </div>
            <p className="footer__logo-content ">
            Rapid Rentals mission is to facilitate a hassle-free car rental experience so you can focus on what’s behind the steering wheel whilst we take care of all your transportation needs.
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer_link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6"  className="social">
            <div className="mb-4">
              <h5 className="footer_link-title mb-4">Connect With Us</h5>
              <p className="flinks">A3, L3, 305-C, IFZA Business Park, DSO, Dubai, UAE.</p>
              {/* <p className="flinks"><a href="tel:+971503661754">+971 50 366 1754</a></p> */}
              <p className="flinks"><a href="tel:+971558654999">+971 55 865 4999</a></p>
              <p className="flinks"><a href="mailto:info@rapidenterprises.ae">info@rapidenterprises.ae</a></p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12" className="social">
            <div className="mb-4">
              <h5 className="footer_link-title mb-4">Our Socials</h5>
              <div className="f-icons">
                <a href="https://www.facebook.com/rapidrentalsae" target="_blank" rel="noopener noreferrer">
                  <FaFacebook style={{ color: 'white', fontSize: '22px' }} />
                </a>
                <a href="https://www.instagram.com/rapidrentalsuae/" target="_blank" rel="noopener noreferrer">
                  <FaInstagram style={{ color: 'white', fontSize: '22px' }} />
                </a>
                <a href="https://www.linkedin.com/company/rapidrentals-ae/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn style={{ color: 'white', fontSize: '22px' }} />
                </a>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="copyright d-flex align-items-center justify-content-center gap-1 pt-4">
              Rapid Rentals Ⓒ 2025. All Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
